<template>
  <div class="qrcode-create">
    <router-back></router-back>
    <div class="qrcode-create-content">
      <el-row class="qrcode-create-content-info">
        <el-col :span="6" class="icon">
          <i class="iconfont icon-erweima"></i>
        </el-col>
        <el-col :span="18">
          <p class="info-title">编辑取货码</p>
          <p class="tip">吸引增长新客户，老客户定向福利</p>
        </el-col>
      </el-row>

      <el-form ref="form" class="qrcode-create-content-form" :model="form" label-width="80px">
        <el-form-item label="取货码名称">
          <span>{{form.name}}</span>
        </el-form-item>

        <el-form-item label="制作总量">
          <el-col :span="8">
            {{form.total}}
          </el-col>
          <el-col :span="8"><span class="tip pl">单次生成取货码数量</span></el-col>
        </el-form-item>
        <el-form-item label="适用饮品">
          <div>
            <el-radio :label="0" v-model.number="form.drinkLimit">不限饮品</el-radio>
          </div>
          <div>
            <el-radio :label="1" v-model.number="form.drinkLimit">指定饮品参与</el-radio>
          </div>
          <div>
            <el-radio :label="2" v-model.number="form.drinkLimit">指定饮品不参与</el-radio>
          </div>
          <div class="item-choose" v-if="form.drinkLimit !==0">
            <p class="remark"><span>以下饮品<template v-if="form.drinkLimit ===2">不</template>参与活动 <i>(点击标签可删除)</i></span>
              <el-button type="text" @click="loadDrinks">修改</el-button>
            </p>
            <div>
              <el-tag
                size="small"
                v-for="tag in drinks"
                :key="tag.drinkUuid"
                @close="handleClose(drinks,tag)"
                closable
                :type="tag.type">
                {{tag.name}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="适用设备">
          <div>
            <el-radio :label="0" v-model.number="form.deviceLimit">不限设备</el-radio>
          </div>
          <div>
            <el-radio :label="1" v-model.number="form.deviceLimit">指定设备参与</el-radio>
          </div>
          <div>
            <el-radio :label="2" v-model.number="form.deviceLimit">指定设备不参与</el-radio>
          </div>
          <div class="item-choose" v-if="form.deviceLimit !==0">
            <p class="remark"><span>以下设备<template v-if="form.deviceLimit ===2">不</template>参与活动 <i>(点击标签可删除)</i></span>
              <el-button type="text" @click="loadDevices">修改</el-button>
            </p>
            <div>
              <el-tag
                size="small"
                v-for="tag in devices"
                :key="tag.deviceUuid"
                @close="handleClose(devices,tag)"
                closable>
                {{tag.deviceName}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="取用限制" prop="region">
          <el-col :span="8">
            <el-select v-model.number="form.takeLimit" placeholder="请设置取用限制">
              <el-option label="单品单次" :value="0"></el-option>
              <el-option label="无限制" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8"><span class="tip pl">无限制代表一次可取所有饮品</span></el-col>
        </el-form-item>
        <el-form-item label="可用次数">
          <el-col :span="8">
            {{form.maxUseTime}}
          </el-col>
          <el-col :span="8"><span class="tip">一个取货码最多使用多少次</span></el-col>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-col :span="21">
            <el-date-picker
              :picker-options="pickerOptions"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-model="date" style="width: 100%;"></el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-checkbox v-model="long" value="1" class="long">长期</el-checkbox>
          </el-col>
          <el-col><span class="tip">活动仅在有效期内才可用，超过有效期自动关闭</span></el-col>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSubmit">保存修改</el-button>
        </el-form-item>
      </el-form>
    </div>
    <c-dialog :visible.sync="getDrinkVisible" :title="'设备饮品'" :width="'56rem'" :height="'36rem'">
      <el-button slot="title" size="mini" type="primary" @click="handleGetDrink">确定</el-button>
      <drink-list ref="drinkList"></drink-list>
    </c-dialog>

    <c-dialog :visible.sync="bindDeviceVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">设备列表</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click="handleBindDevice">绑定设备</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="name" placeholder="请输入设备名称" @change="inputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices"></devices>
    </c-dialog>

  </div>
</template>

<script>
  import DrinkList from '@/components/DrinkGroup'
  import Devices from '@/components/Device'
  import CDialog from '@/components/Dialog'
  import {qrCodeEdit, qrCodeDetail} from '@/api/marketing/qrcode'

  export default {
    name: 'qr-code-edit',
    components: {
      DrinkList,
      Devices,
      CDialog
    },
    activated: function () {
      this.loadQrCode(this.$route.params.uuid)
    },
    data: function () {
      return {
        getDrinkVisible: false,
        bindDeviceVisible: false,
        form: {
          uuid:'',
          name: '',
          total: 1,
          drinkLimit: 0,
          deviceLimit: 0,
          deviceUuid: [],
          deviceName: '',
          drinkUuid: [],
          drinkName: '',
          takeLimit: 0,
          takeTime: 1,
          startAt: 0,
          endAt: 0
        },
        date: [],
        drinks: [],
        devices: [],
        name: '',
        long: false,
        pickerOptions: {
          shortcuts: [{
            text: '一周有效',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '一月有效',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '三月有效',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    methods: {
      loadDrinks() {
        this.getDrinkVisible = true
        let vue = this
        this.$nextTick(function () {
          vue.$refs.drinkList.loadDrink(vue.drinks)
        })
      },

      loadDevices() {
        this.bindDeviceVisible = true
        let vue = this
        this.$nextTick(function () {
          this.$refs.devices.loadDevices('', vue.devices)
        })
      },

      handleBindDevice() {
        let devices = this.$refs.devices.getDeviceDetail() || []
        if (devices.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }
        this.devices = devices
        this.bindDeviceVisible = false
      },

      inputChange(val) {
        this.$refs.devices.loadDevices(val, this.devices)
      },

      handleGetDrink() {
        this.drinks = (this.$refs.drinkList.getCheckDrink() || [])
        this.getDrinkVisible = false
      },
      handleClose(obj, tag) {
        obj.splice(obj.indexOf(tag), 1);
      },

      loadQrCode(uuid) {
        qrCodeDetail({uuid: uuid}).then(res => {
          let data = res.data
          let form = this.form
          form.uuid = data.qrCode.uuid
          form.name = data.qrCode.name
          form.total = data.qrCode.total
          form.deviceLimit = data.qrCode.deviceLimit
          form.drinkLimit = data.qrCode.drinkLimit
          form.maxUseTime = data.qrCode.maxUseTime
          form.takeLimit = data.qrCode.takeLimit
          let devs = data.devices || []
          let devices = []

          devs.forEach(device => {
            devices.push({deviceName: device.deviceName, deviceUuid: device.deviceUUID})
          })
          this.devices = devices

          let drks = data.drinks || []
          let drinks = []

          drks.forEach(drink => {
            drinks.push({drinkUuid: drink.drinkUUID, name: drink.drinkName, engName: drink.drinkEngName})
          })
          this.drinks = drinks
          this.long = (new Date(data.qrCode.endAt * 1000).getFullYear() - (new Date().getFullYear()) > 50)
          this.date = [new Date(data.qrCode.startAt * 1000), new Date(data.qrCode.endAt * 1000)]

        })
      },

      handleSubmit() {
        let deviceName = '无限制'
        let drinkName = '无限制'
        if (this.form.deviceLimit !== 0) {
          if (this.devices.length === 0) {
            this.$message.warning('请选取对应设备')
            return
          }
          deviceName = ''
          this.devices.forEach(device => {
            this.form.deviceUuid.push(device.deviceUuid)
            deviceName += device.deviceName + `,`
          })
        } else {
          this.form.deviceUuid = []
        }

        if (this.form.drinkLimit !== 0) {
          if (this.drinks.length === 0) {
            this.$message.warning('请选取对应饮品')
            return
          }
          drinkName = ''
          this.drinks.forEach(drink => {
            this.form.drinkUuid.push(drink.drinkUuid)
            drinkName += drink.name + `,`
          })
        } else {
          this.form.drinkUuid = []
        }

        this.form.drinkName = drinkName
        this.form.deviceName = deviceName


        if (this.long !== 0) {
          let date = new Date()
          this.form.startAt = Math.floor(date.getTime() / 1000)
          date.setFullYear(date.getFullYear() + 100, date.getMonth(), date.getDay())
          this.form.endAt = Math.floor(date.getTime() / 1000)
        } else {
          if (!this.date.length) {
            this.$message.warning('请设置活动时间')
            return
          }
          this.form.startAt = Math.floor(this.date[0] / 1000)
          this.form.endAt = Math.floor(this.date[1] / 1000)
        }

        qrCodeEdit(this.form).then(res => {
          this.$message.success('调整成功')
          this.form = {
            name: '',
            total: 1,
            drinkLimit: 0,
            deviceLimit: 0,
            deviceUuid: [],
            deviceName: [],
            drinkUuid: [],
            drinkName: [],
            takeLimit: 0,
            takeTime: 1,
            startAt: 0,
            endAt: 0
          }
          this.date = []
          this.drinks = []
          this.devices = []
          this.$router.back()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .qrcode-create {
    &-content {
      max-width: 720px;

      margin: 0 auto;

      .tip {
        font-size: 12px;
        color: #999999;
      }

      .pl {
        padding-left: $padding;
      }

      &-info {
        padding: $padding;
        margin-bottom: 32px;
        border-bottom: 1px solid;
        @include set_border_color($--border-color, $--border-color-dark);

        .icon {
          text-align: center;
          padding-top: $padding;

          .iconfont {
            background-color: $-theme-light-1;
            border-radius: 15%;
            padding: $padding-4;
            font-size: 48px;
            color: white;
          }
        }

        .info-title {
          font-size: 18px;
          color: #333333;
          line-height: 1.75;
        }
      }


      &-form {
        padding: 0 $padding;

        .item-choose {
          border: 1px dashed $_theme-light-7;
          border-radius: $border-radius;
          padding: $padding;
        }

        .el-tag {
          margin-right: $padding-8;
        }

        .line {
          text-align: center;
        }

        .long {
          margin-left: $padding-8;
        }

        .remark {
          span, .el-button {
            font-size: 13px;
          }

          span {
            color: #333333;
          }

          i {
            font-size: 12px;
            color: #999999;
            display: inline-block;
            margin-right: $padding;
            margin-left: $padding-4;
          }
        }

      }
    }


  }
</style>
